import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import styles from "../styles/select.module.scss"

const Select = ({ children, onChange }) => (
    <div className={styles.selectHolder}>
        <select onChange={onChange}>
            {children}
        </select>
        <FontAwesomeIcon color="#fdbb13" icon={faChevronDown} size="1x"/>
    </div>
)

export default Select 