import React, { useState } from "react"
import Axios from "axios"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Select from "../components/select"
import Loader from "../components/loader"
import bodyStyles from "../styles/devenez-partenaire.module.scss"
import { useTranslation } from "../hooks/useTranslation"

const URL = "https://lc3byb763i.execute-api.ca-central-1.amazonaws.com/Prod/send";

const COUNTRIES = [
    {
        name: "Canada",
        states: [ "Québec", "Ontario", "Alberta" ]
    },
    {
        name: "United States",
        states: [ "New York", "California" ]
    },
    {
        name: "Mexico",
        states: [ "Mexico", "Yucatan" ]
    }
];

const DevenezPartenairePage = () => {

    const t = useTranslation();
    const [isPersonnelSelected, setIsPersonnelSelected] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(COUNTRIES[0]);

    const [formData, setFormData] = useState({
        title: 'Mdme',
        country: 'Canada',
        state: 'Québec'
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [hasError, setHasError] = useState(false);

    // Handlers

    const updateFormData = (key, value) => {
        formData[key] = value;
        setFormData({...formData});
    }

    const onCountrySelect = (name) => {
        const country = COUNTRIES.find(c => c.name === name);
        setSelectedCountry(country);
        formData.country = name;
    }

    const extractFromErrors = (submitted = isSubmitted) => {
        if (!submitted) return [];

        const { title, firstName, lastName, address, country, state, city, code, phone, email } = formData;

        let errors = [];
        if (!title || title.length === 0) errors.push("title")
        if (!firstName || firstName.length === 0) errors.push("firstName")
        if (!lastName || lastName.length === 0) errors.push("lastName")
        if (!address || address.length === 0) errors.push("address")
        if (!country || country.length === 0) errors.push("country")
        if (!state || state.length === 0) errors.push("state")
        if (!city || city.length === 0) errors.push("city")
        if (!code || code.length === 0) errors.push("code")
        if (!phone || phone.length === 0) errors.push("phone")
        if (!email || email.length === 0) errors.push("email")

        return errors;
    }

    const submitForm = async () => {
        setIsSubmitted(true);
        
        const errors = extractFromErrors(true);
        console.log(errors)
        if (errors.length > 0) return;

        setIsLoading(true);

        console.log(formData);

        const { title, firstName, lastName, address, country, state, city, code, phone, email } = formData;
        const body = {
            "toEmails": ["nicstaubin@gmail.com", "info@doctoctoc.ca"],
            "subject": "DocTocToc | New partneship request",
            "message": `partner type: ${isPersonnelSelected ? "personal" : "corporate"} \n\nname: ${title} ${firstName} ${lastName} \nemail: ${email} \nphone: ${phone} \n\naddress: \n${address} \n${country}, ${state}, ${city} ${code}`
        };

        try {
            await Axios.post(URL, body);
            setIsSent(true);
        } catch(e) {
            console.log(e);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
        
    }

    // Rendering

    const errors = extractFromErrors();
    
    return (
        <div>
            <Layout>
                <div className={bodyStyles.body}>

                    <h1>
                        {t("devenezPartenaire.devenez")}
                    </h1>
                    <h1>
                        {t("devenezPartenaire.partenaire")}
                    </h1>

                    <div className={bodyStyles.form}>

                        <div className={bodyStyles.form_header}>
                            <div className={isPersonnelSelected ? bodyStyles.active : ""} onClick={() => setIsPersonnelSelected(true)}>
                                {t("devenezPartenaire.personnel")}
                                <div className={bodyStyles.separator}/>
                            </div>

                            <div className={!isPersonnelSelected ? bodyStyles.active : ""} onClick={() => setIsPersonnelSelected(false)}>
                                {t("devenezPartenaire.corporatif")}
                                <div className={bodyStyles.separator}/>
                            </div>
                        </div>

                        <div className={bodyStyles.row}>
                            <div className={bodyStyles.input_holder}>
                                <label>{t("devenezPartenaire.titre")}</label>
                                <Select 
                                    className={errors.includes("title") ? bodyStyles.error : null}
                                    onChange={e => updateFormData('title', e.target.value)}>
                                    <option value="Mdme">{t("devenezPartenaire.mdme")}</option>
                                    <option value="Mr">{t("devenezPartenaire.mr")}</option>
                                    <option value="Autre">{t("devenezPartenaire.autre")}</option>
                                </Select>
                            </div>
                            <div className={bodyStyles.input_holder} style={{flex: 1}}>
                                <label>{t("devenezPartenaire.prenom")}</label>
                                <input 
                                    className={errors.includes("firstName") ? bodyStyles.error : null}
                                    onChange={e => updateFormData('firstName', e.target.value)}
                                    type="text" 
                                    placeholder={t("devenezPartenaire.prenom")}/>
                            </div>
                            <div className={bodyStyles.input_holder} style={{flex: 1}}>
                                <label>{t("devenezPartenaire.nom")}</label>
                                <input 
                                    className={errors.includes("lastName") ? bodyStyles.error : null}
                                    onChange={e => updateFormData('lastName', e.target.value)}
                                    type="text" 
                                    placeholder={t("devenezPartenaire.nom")}/>
                            </div>
                        </div>

                        <div className={bodyStyles.row}>
                            <div className={bodyStyles.input_holder} style={{flex: 1}}>
                                <label>{t("devenezPartenaire.adresse")}</label>
                                <input 
                                    className={errors.includes("address") ? bodyStyles.error : null}
                                    onChange={e => updateFormData('address', e.target.value)}
                                    type="text" 
                                    placeholder={t("devenezPartenaire.adresse")}/>
                            </div>
                        </div>

                        <div className={bodyStyles.row}>
                            <div className={bodyStyles.input_holder} style={{flex: 1}}>
                                <label>{t("devenezPartenaire.pays")}</label>
                                <Select 
                                    className={errors.includes("country") ? bodyStyles.error : null}
                                    onChange={e => onCountrySelect(e.target.value)}>
                                    { COUNTRIES.map(c => <option value={c.name}>{c.name}</option>)}
                                </Select>
                            </div>
                            <div className={bodyStyles.input_holder} style={{flex: 1}}>
                                <label>{t("devenezPartenaire.etat")}</label>
                                <Select 
                                    className={errors.includes("state") ? bodyStyles.error : null}
                                    onChange={e => updateFormData('state', e.target.value)}
                                    disabled={!selectedCountry}>
                                    { selectedCountry && selectedCountry.states.map(s => <option value={s}>{s}</option>)}
                                </Select>
                            </div>
                            <div className={bodyStyles.input_holder} style={{flex: 1}}>
                                <label>{t("devenezPartenaire.ville")}</label>
                                <input
                                    className={errors.includes("city") ? bodyStyles.error : null}
                                    onChange={e => updateFormData('city', e.target.value)} 
                                    type="text" 
                                    placeholder={t("devenezPartenaire.ville")}/>
                            </div>
                        </div>

                        <div className={bodyStyles.row}>
                            <div className={bodyStyles.input_holder} style={{flex: 1}}>
                                <label>{t("devenezPartenaire.code")}</label>
                                <input 
                                    className={errors.includes("code") ? bodyStyles.error : null}
                                    onChange={e => updateFormData('code', e.target.value)}
                                    type="text" 
                                    placeholder={t("devenezPartenaire.code")}/>
                            </div>
                            <div className={bodyStyles.input_holder} style={{flex: 1}}>
                                <label>{t("devenezPartenaire.numero")}</label>
                                <input 
                                    className={errors.includes("phone") ? bodyStyles.error : null}
                                    onChange={e => updateFormData('phone', e.target.value)}
                                    type="text" 
                                    placeholder={t("devenezPartenaire.numero")}/>
                            </div>
                            <div className={bodyStyles.input_holder} style={{flex: 1}}>
                                <label>{t("devenezPartenaire.courriel")}</label>
                                <input 
                                    className={errors.includes("email") ? bodyStyles.error : null}
                                    onChange={e => updateFormData('email', e.target.value)}
                                    type="text" 
                                    placeholder={t("devenezPartenaire.courriel")}/>
                            </div>
                        </div>

                        {errors.length > 0 && <p className={bodyStyles.hasError}>{t("devenezPartenaire.missingFields")}</p>}
                        { !isSent &&
                            <button onClick={submitForm} disabled={isLoading}>
                                {isLoading ?  <Loader/> : t("devenezPartenaire.suivant")}
                            </button>
                        }
                        {isSent && <p className={bodyStyles.thankYou}>{t("devenezPartenaire.thankYou")}</p>}
                        {hasError && <p className={bodyStyles.hasError}>{t("devenezPartenaire.error")}</p>}

                    </div>

                </div>
            </Layout>

            <Footer color="#fdbb13" />
        </div>
    )
}

export default DevenezPartenairePage